<template>
<b-card>
    <h4 class="title-card-Status">Historic Status</h4>
    <div id="chart">
        <vue-apex-charts type="rangeBar" height="135" :options="chartOptions" :series="series"></vue-apex-charts>
    </div>
</b-card>
</template>

<script>
import {
    BCard,
    BAvatar
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {
    $themeColors
} from '@themeConfig'

export default {
    components: {
        BCard,
        BAvatar,
        VueApexCharts
    },
    data() {
        return {
            series: [
                // on
                {
                    name: 'on',
                    data: [{
                        x: 'Status',
                        y: [
                            utcToLocalDate(new Date(2022, 7, 28, 1, 12)),
                            utcToLocalDate(new Date(2022, 7, 28, 8)),

                        ],
                        goals: [{
                            
                            value:  utcToLocalDate(new Date(2022, 7, 28, 4, 50)),
                            strokeWidth: 10,
                            strokeHeight: 0,
                            strokeLineCap: 'round',
                            strokeColor: '#775DD0'
                        }],
                        fillColor: this.resolveColor('on'),

                    }, ]
                },
                // Off
                {
                    name: 'off',
                    data: [{
                            x: 'Status',
                            y: [
                                utcToLocalDate(new Date(2022, 7, 28, 8)),
                                utcToLocalDate(new Date(2022, 7, 28, 9, 30))
                            ],
                            fillColor: this.resolveColor('off')
                        },

                    ]
                },
                // no-communication
                {
                    name: 'no-communication',
                    data: [{
                            x: 'Status',
                            y: [
                                utcToLocalDate(new Date(2022, 7, 28, 9, 30)),
                                utcToLocalDate(new Date(2022, 7, 28, 11))
                            ],
                            fillColor: this.resolveColor('no-communication')
                        },

                    ]
                },
                // servere-use
                {
                    name: 'servere-use',
                    data: [{
                            x: 'Status',
                            y: [
                                utcToLocalDate(new Date(2022, 7, 28, 11)),
                                utcToLocalDate(new Date(2022, 7, 28, 15, 35))
                            ],
                            fillColor: this.resolveColor('servere-use')

                        },

                    ]
                },
            ],
            chartOptions: {
                chart: {
                    height: 135,
                    type: 'rangeBar',
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                        tools: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '50%',
                        rangeBarGroupRows: true
                    },
                    dataLabels: {
                        enabled: false,

                    },
                },
                //colors: [$themeColors.success, $themeColors.secondary, $themeColors.primary, $themeColors.warning],
                fill: {
                type: 'solid',
                opacity: 0.9
              },
                xaxis: {
                    type: 'datetime',
                    min: utcToLocalDate(new Date(2022, 7, 28, 0, 0))
                },
                yaxis: {
                    labels: false,
                },
                legend: {
                    //show: false,
                    markers: {

                        fillColors: [$themeColors.success, $themeColors.secondary, $themeColors.primary, $themeColors.warning],

                    },
                },
                tooltip: {
                    custom(data) {
                        const hourStart = new Date(data.y1).getUTCHours();
                        const minuteStart = new Date(data.y1).getUTCMinutes();
                        const hourEnd = new Date(data.y2).getUTCHours();
                        const minuteEnd = new Date(data.y2).getUTCMinutes();
                        const values = data.ctx.rangeBar.getTooltipValues(data);
                        return `${'<div class="px-1 py-50"><span>'}${
                          values.seriesName + hourStart + ":" + minuteStart + " to " +  hourEnd + ":" + minuteEnd
                        }</span></div>`
                    },
                },

            },

        }
    },
    methods: {
        resolveColor(status) {
            if (status === 'failure') return $themeColors.danger
            if (status === 'off') return $themeColors.secondary
            if (status === 'no-communication') return $themeColors.primary
            if (status === 'servere-use') return $themeColors.warning
            if (status === 'iddle-use') return $themeColors.info
            if (status === 'on') return $themeColors.success
            return $themeColors.success

        }
    },
    props: {
        // model: {
        //   type: String,
        //   required: true,
        // },
        // urlImage: {
        //   type: String,
        //   required: true,
        // },
        // chassis: {
        //   type: String,
        //   required: true,
        // },
        // status: {
        //   type: String,
        //   required: true,
        // },
        //  lastConnection: {
        //   type: String,
        //   required: true,
        // },
    },
}

function utcToLocalDate(date) {
    var myDate = new Date(date);
    var offset = myDate.getTimezoneOffset() * 60 * 1000;

    var withOffset = myDate.getTime();
    var withoutOffset = withOffset - offset;

    return withoutOffset;
}
</script>

<style>
.title-card-Status {
    margin-bottom: -1rem !important;
}
</style>
