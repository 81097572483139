<template>
<b-card class="mb-3 ">
    <b-row class="no-gutters">
        <b-col md="4" class="div-img d-flex align-items-center justify-content-center " :style="{height: heightImg}">
            <img :src="`${urlImage}`" class="card-img">
            <!-- :src="require(`../assets/${urlImage}`)" -->
        </b-col>
        <b-col xl="8" md="8" sm="8">
            <div class="card-body ">
                <b-card-text> Model: <strong>{{ model }}</strong></b-card-text>
                <b-card-text> Chassis: <strong>{{ chassis }}</strong></b-card-text>
                <b-card-text> Status: &nbsp;&nbsp;<b-badge :variant="`${setColor(status)}`" class="badge-glow">
                        {{ status }}
                    </b-badge></b-card-text>
            </div>
        </b-col>

    </b-row>
</b-card>
</template>

<script>
import { BCard, BCardText, BRow, BCol, BBadge } from 'bootstrap-vue'
//import SimpleIndicatorCard from './SimpleIndicatorCard.vue'

export default {
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BBadge
        //SimpleIndicatorCard
    },
    props: {
        model: {
            type: String,
            required: true,
        },
        urlImage: {
            type: String,
            required: true,
        },
        chassis: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            required: true,
        },
        lastConnection: {
            type: String,
            required: true,
        },
        heightImg: {
            type: String,
            default: 'auto',
        },
    },
    methods: {
        setColor: function (status) {

            if (status === 'failure') return 'danger'
            if (status === 'off') return 'secondary'
            if (status === 'no-communication') return 'primary'
            if (status === 'servere-use') return 'warning'
            if (status === 'iddle-use') return 'info'
            if (status === 'on') return 'success'
            return 'primary'
        }
    }
}
</script>

<style>
.card-img{
    max-width: 200px !important;
    max-height: 200px !important;
    min-width: 50px !important;
    min-height: 50px !important;
}
.card-text {
    font-size: 0.95em !important;
}

.no-gutters {
    margin: -1.5rem !important;
}


.div-img{
    background-color: #F3F2F7 !important;
}
</style>
