<template>
<section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="assetData === undefined">
        <h4 class="alert-heading">
            Error fetching asset data
        </h4>
        <div class="alert-body">
            No asset found with this asset id. Check
            <b-link class="alert-link" :to="{ name: 'apps-asset-list'}">
                Asset List
            </b-link>
            for other assets.
        </div>
    </b-alert>
    <b-row class="justify-content-between">
        <b-col col="11">
            <p class="float-left text-primary font-weight-bold text-header">#{{assetData.plate}}</p>
            <p class="float-right text-header font-weight-bold">Last Connection: {{assetData.lastConection}}</p>
        </b-col>
        
    </b-row>
    <b-row >
        <b-col xl="6" md="6" sm="12"  >
            <card-info heightImg="198px" :lastConnection="`${assetData.lastConection}`" :model="`${assetData.modelName} - ${assetData.modelYear}`" :chassis="`${assetData.plate}`" :status="`${assetData.status}`" :urlImage="`${assetData.avatar != '' ? assetData.avatar : 'https://i.pinimg.com/originals/79/1b/d1/791bd1320c0e1318eb886a636d4adb3d.png'}`" />
        </b-col>
        <b-col xl="6" md="6" sm="12"  >
            <status-card />
        </b-col>
    </b-row>
    <b-row>
        <b-col xl="6" md="6" sm="12">
            <b-card title="Location" style="height: 550px;">
                <l-map :style="[ {maxHeight:mapMaxHeight}, {minHeight:mapMinHeight}]" :zoom="zoom" :center="center" >
                    <l-tile-layer :url="url" ></l-tile-layer>
                    <l-marker :lat-lng="marker.latLong">
                        <l-icon :class-name="`someExtraClass asset-${assetData.status} `" :icon-size="dynamicSize" :tooltip-anchor="dynamicTooltip" :popup-anchor="dynamicPopup" :icon-anchor="dynamicAnchor">
                            <img class="with-icon" :src="require('@/assets/images/assets/Car.png')">
                        </l-icon>
                    </l-marker>
                </l-map>
            </b-card>
        </b-col>
        <b-col xl="6" md="6" sm="12">
            <div>
                <b-row>

                    <b-col xl="4" md="4" sm="6" v-show="assetData.velocity">
                        <simple-indicator-card title="Velocity" icon="ActivityIcon" :statistic="`${assetData.velocity}`" statistic-title="Km/h" color="info" />
                    </b-col>
                    <b-col xl="4" md="4" sm="6" v-show="assetData.horimeter">
                        <simple-indicator-card title="Horimeter" icon="ClockIcon" :statistic="`${assetData.horimeter}`" statistic-title="Hours" color="info" />
                    </b-col>
                    <b-col xl="4" md="4" sm="6" v-show="assetData.odometer">
                        <simple-indicator-card title="Odometer" icon="AirplayIcon" :statistic="`${assetData.odometer}`" statistic-title="Km" color="info" />
                    </b-col>
                    <b-col xl="4" md="4" sm="6" v-show="assetData.consumer">
                        <simple-indicator-card title="Consumer" icon="DropletIcon" :statistic="`${assetData.consumer}`" statistic-title="Km/L" color="info" />
                    </b-col>
                    <b-col xl="4" md="4" sm="6" v-show="assetData.engine">
                        <simple-indicator-card title="Engine" icon="ThermometerIcon" :statistic="`${assetData.engine}`" statistic-title="C" color="info" />
                    </b-col>
                    <b-col xl="4" md="4" sm="6" v-show="assetData.transmission">
                        <simple-indicator-card title="Transmission" icon="ThermometerIcon" :statistic="`${assetData.transmission}`" statistic-title="C" color="info" />
                    </b-col>
                    <b-col xl="4" md="4" sm="6" v-show="assetData.oil">
                        <simple-indicator-card title="Hydraulic oil" icon="ThermometerIcon" :statistic="`${assetData.oil}`" statistic-title="C" color="info" />
                    </b-col>
                    <b-col xl="4" md="4" sm="6" v-show="assetData.fuel">
                        <simple-gauge-indicator :fuel="`${assetData.fuel}`" />
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
    <b-row v-show="assetData.engineTemp && assetData.velocity">
        <!-- Inicio componente CompleteIndicator -->
        <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="Km/h" title="Velocity" icon="ActivityIcon" :axisX="assetData.hoursConnection" :axisY="assetData.engineTemp" :temperature="`${assetData.velocity}`" />
        </b-col>
    </b-row>
    <b-row v-show="assetData.engineTemp && assetData.engine">
        <!-- Inicio componente CompleteIndicator -->
        <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="C" title="Engine Temperature" icon="ThermometerIcon" :axisX="assetData.hoursConnection" :axisY="assetData.engineTemp" :temperature="`${assetData.engine}`" />
        </b-col>
    </b-row>
    <b-row v-show="assetData.engineTemp && assetData.oil">
        <!-- Inicio componente CompleteIndicator -->
        <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="C" title="Hydraulic Oil Temperature" icon="ThermometerIcon" :axisX="assetData.hoursConnection" :axisY="assetData.engineTemp" :temperature="`${assetData.oil}`" />
        </b-col>
    </b-row>
    <b-row v-show="assetData.engineTemp && assetData.transmission">
        <!-- Inicio componente CompleteIndicator -->
        <b-col xl="12" md="12" sm="12">
            <complete-indicator-card unity="C" title="Transmission Temperature" icon="ThermometerIcon" :axisX="assetData.hoursConnection" :axisY="assetData.engineTemp" :temperature="`${assetData.transmission}`" />
        </b-col>
    </b-row>

</section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BImg
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import assetStoreModule from '../assetStoreModule'

import SimpleIndicatorCard from '../asset-preview/indicators/SimpleIndicatorCard.vue'
import SimpleGaugeIndicator from '../asset-preview/indicators/SimpleGaugeIndicator.vue'
import CompleteIndicatorCard from '../asset-preview/indicators/CompleteIndicatorCard.vue'
import CardInfo from '../asset-preview/indicators/CardInfo.vue'
import StatusCard from '../asset-preview/indicators/StatusCard.vue'

import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { latLng, icon, Icon } from 'leaflet'
import L from 'leaflet';

import BCardCode from '@core/components/b-card-code/BCardCode.vue'

export default {
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        Logo,
        BImg,

        SimpleIndicatorCard,
        SimpleGaugeIndicator,
        CompleteIndicatorCard,
        CardInfo,

        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,

        BCardCode,
        StatusCard
    },
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 17,
            center: [-19.9613815, -44.0516314],
            icon: L.icon({

                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
            staticAnchor: [16, 37],
            
            iconSize: 40,
            marker: {
                latLong: [-19.9613815, -44.0516314],
                
            },
            mapHeight:'45vh',
            mapMaxHeight:'470px',
            mapMinHeight:'470px',

        };
    },
    computed: {
        dynamicSize() {
            this.toltipAnchor = [27, 30];
            return [this.iconSize, this.iconSize * 1.15];
        },
        dynamicAnchor() {
            if (this.selectedIcon == true) {
                return [27, 30];
            } else {
                return [15, 13];
            }
        },
        dynamicTooltip() {
            if (this.selectedIcon == true) {
                return [25, 0];
            } else {
                return [7, -15];
            }
        },
        dynamicPopup() {
            if (this.selectedIcon == true) {
                return [2, -27];
            } else {
                return [-7, -13];
            }
        },

    },
    mounted() {

       

        //map.addControl(new L.Control.Fullscreen());

    },
    setup() {
        const assetData = ref(null)

        const ASSET_APP_STORE_MODULE_NAME = 'app-asset'

        // Register module
        if (!store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.registerModule(ASSET_APP_STORE_MODULE_NAME, assetStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.unregisterModule(ASSET_APP_STORE_MODULE_NAME)
        })

        store.dispatch('app-asset/fetchAsset', { id: router.currentRoute.params.id })
            .then(response => {
                assetData.value = response.data.asset

            })
            .catch(error => {
                if (error.response.status === 404) {
                    assetData.value = undefined
                }
            })

        return {
            assetData,

        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';

.someExtraClass {
    //background-color: #28c76f;
    padding: 4px;
    border-radius: 50px 50px 50px 50px;
    box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: auto !important;
    height: auto !important;
}

 .asset-on {
    background-color: #28c76f !important;
}

.asset-off {
    background-color: #82868b !important;
}

.asset-failure {
    background-color: #ef5258 !important;
}

.asset-no-communication {
    background-color: #746be8 !important;
}
.asset-servere-use {
    background-color: #ff9d54 !important;
}

.asset-iddle-use {
    background-color: #ff9d54 !important;
}
.with-icon {
    width: 40px;
}
.vue2leaflet-map {
    &.leaflet-container {
        height: 445px;
    }
}
.text-header {
    //font-size: clamp(0.5em, 0.7em + 0.2vw, 5.5em);
    font-size: 1em;
}


//@media
@media screen and (max-width: 540px) {
    .text-header {
       font-size: 0.8em;
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

</style>


