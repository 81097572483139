<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex  align-items-center mb-3 " style="margin-bottom: 0 !important;">
          <h4 class="card-title mb-50 mb-sm-0" >
            {{ title }}
          </h4>
          
        </div>

        <!-- chart -->
        <vue-apex-charts
        type="line"
        height="180"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
      />
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper align-self-center"
      >
      <b-avatar
      class="mb-1"
      variant="light-info"
      size="75"
      style="margin-bottom: 0.5em !important;"
    >
      <feather-icon
        size="38"
        :icon="icon"
      />
    </b-avatar>

        <h2 class="mb-25">
          {{ temperature }}{{unity}}
        </h2>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton, BAvatar
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'


import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    temperature: {
      type: String,
      required: true,
    },
    axisX: {
      type: Array,
      required: true,
    },
    axisY: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    unity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lineChartSimple: {
    series: [
      {
        data:this.axisY,
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.warning],
      },
      colors: [$themeColors.info],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        custom(data) {
          console.log(data);
          return `${'<div class="px-1 py-50"><span>'}${
              [data.series[0][data.dataPointIndex]]
          }ºC</span></div>`
        },
      },
      xaxis: {
        categories: this.axisX
        
        ,
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },
    }
  },
  created() {
    //this.$http.get('/card/card-analytics/revenue-report').then(res => { this.revenue_report = res.data })
  },
}
</script>

<style>
.card-revenue-budget .revenue-report-wrapper {
  padding: 1.2rem 1rem 0rem 1rem;
}
</style>
