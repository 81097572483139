import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAssets(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios
                    .get('/apps/asset/assets', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAsset(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/apps/asset/assets/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}